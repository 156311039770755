import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useSocket } from "../../redux/SocketContext";
import { IoSendSharp } from "react-icons/io5";
import { Preloader } from "../../components/elements/Preloader";

const CustomerChat = () => {
  const { uid } = useAppSelector((store: RootState) => store.auth);
  const socket: any = useSocket();
  const [typedMessage, setTypedMessage] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const [allChats, setAllChats] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState<any>({
    _id: "",
    name: "",
    lastMessage: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    if (!socket) return;
    socket.emit("joinRoom", { uid });
    socket.on("receiveMessage", (data: any) => {
      console.log("receiveMessage", data);
      const messageWithTimestamp = {
        ...data,
        createdAt: new Date(),
        id: data._id || Date.now(),
      };
      setMessages((prevMessages: any) => [
        ...prevMessages,
        messageWithTimestamp,
      ]);
    });
    socket.emit("getChatHistory", {
      userId: "672e0a080d3f650992d80689",
      contactId: uid,
    });
    socket.on("chatHistory", (data: any) => {
      const chatHistoryWithTimestamps = data.map((message: any) => ({
        ...message,
        createdAt: new Date(message.createdAt),
        id: message._id || Date.now(),
      }));
      setMessages(chatHistoryWithTimestamps);
    });

    socket.emit("getCustomersOnChatHistory", {});
    socket.on("customersOnChatHistory", (chatHistory: any) => {
      setAllChats(chatHistory);
    });

    return () => {
      socket.off("customersOnChatHistory");
      socket.off("receiveMessage");
    };
  }, [socket, uid]);

  useEffect(() => {
    setIsLoading(true);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleSendMessage = async () => {
    if (typedMessage.trim() !== "" && socket) {
      const message: any = {
        sender: uid,
        receiver: selectedChat[0]?.receiver,
        message: typedMessage,
      };

      await socket.emit("sendMessage", message);
      setTypedMessage("");
      message.createdAt = new Date();

      setMessages((prevMessages: any) => [...prevMessages, message]);
    }
  };

  const formatTime = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const groupMessagesByDate = (messages: any) => {
    const groupedMessages: any = {};
    messages.forEach((message: any) => {
      const dateKey = message.createdAt.toLocaleDateString();
      if (!groupedMessages[dateKey]) {
        groupedMessages[dateKey] = [];
      }
      groupedMessages[dateKey].push(message);
    });
    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDate(messages);
  const messageDates = Object.keys(groupedMessages);

  const getCustomerMessage = (receiverId: any) => {
    if (socket) {
      socket.emit("getChatHistory", { userId: receiverId, contactId: uid });
      socket.on("chatHistory", (chatHistory: any) => {
        const chatHistoryWithTimestamps = chatHistory.map((message: any) => ({
          ...message,
          createdAt: new Date(message.createdAt),
          id: message._id || Date.now(),
        }));
        setSelectedChat(chatHistoryWithTimestamps);
      });
    }
  };

  return (
    <div>
      <h1 className="text-lg font-bold">Customer Chats</h1>
      <section className="flex gap-3 bg-white p-5 my-3 rounded-md">
        <div className="w-1/3 border rounded-md">
          <div className="flex justify-between items-center px-2 py-4 shadow-md">
            <h3 className="font-semibold text-lg">All Chats</h3>
            <span className="bg-secondary text-white p-1 rounded-full w-7 text-center text-xs">
              {allChats.length}
            </span>
          </div>
          <div className="md:h-[26rem] overflow-auto">
            {isLoading ? (
              <div className="my-3">
                
                <Preloader />
              </div>
            ) : allChats && allChats.length > 0 ? (
              allChats.map((data: any, index: number) => (
                <div
                  key={index}
                  onClick={() => getCustomerMessage(data._id)}
                  className="border-y p-2 py-5 my-2 flex items-center hover:bg-gray-100 cursor-pointer"
                >
                  <img
                    src={data.image}
                    alt={data.name}
                    className="w-10 h-10 rounded-full border border-green-100"
                  />
                  <div className="pl-3">
                    <p className="text-sm text-semibold">{data.name}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-secondary text-center p-3">
                No available chat
              </p>
            )}
          </div>
        </div>
        <section className="border w-2/3 p-2 relative">
          {messageDates.map((date, index) => (
            <div key={index}>
              <h1 className="text-sm font-bold text-center text-green-600 mb-6 mt-2 py-2 rounded-md">
                {date}
              </h1>
              {groupedMessages[date]?.map((message: any) =>
                message.sender === uid ? (
                  <div
                    className="flex items-end justify-end flex-col mb-4"
                    key={message.id}
                  >
                    <h1 className="bg-secondary rounded-br-3xl rounded-l-3xl text-white font-medium text-sm lg:text-base text-right p-4 max-w-[80%] lg:max-w-[60%]">
                      {message.message}
                    </h1>
                    <p className="text-xs text-gray-500 pr-2">
                      {formatTime(message.createdAt)}
                    </p>
                  </div>
                ) : (
                  <div
                    className="flex items-start justify-start flex-col mb-4"
                    key={message.id}
                  >
                    <h1 className="bg-gray-200 rounded-bl-3xl rounded-r-3xl  font-medium text-sm lg:text-base text-left p-4 max-w-[80%] lg:max-w-[60%]">
                      {message.message}
                    </h1>
                    <p className="text-xs text-gray-500 pl-2">
                      {formatTime(message.createdAt)}
                    </p>
                  </div>
                )
              )}
            </div>
          ))}
          {messageDates.length === 0 && (
            <div className="flex items-center justify-center flex-col">
              <img
                src="/assets/empty-chat.png"
                alt="no result"
                className="w-[25rem] h-[20rem]"
              />
              <p className="text-center italic text-gray-500 -ml-10 mt-6">
                Start a new chat by sending a message
              </p>
            </div>
          )}
          {messageDates.length > 0 && (
            <div className="absolute w-[98%] mb-2 bottom-[0]">
              <input
                type="text"
                className="w-full p-4 border border-gray-300 rounded-2xl shadow-md focus:outline-none focus:ring-1 focus:ring-green-200"
                placeholder="Type your message..."
                value={typedMessage}
                onChange={(e) => setTypedMessage(e.target.value)}
              />
              <IoSendSharp
                className="absolute right-3 top-4 w-6 h-6 text-gray-500 hover:text-secondary cursor-pointer"
                onClick={handleSendMessage}
              />
            </div>
          )}{" "}
        </section>
      </section>
    </div>
  );
};

export default CustomerChat;
