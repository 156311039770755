import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import LoadingSpinner from "../elements/LoadingSpinner";
import { API_URL } from "../../App";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

const CreditLimitModal = ({
  id,
  setId,
  setAction1,
  creditLimit,
  setCreditLimit,
  getAllUsers,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const setCreditLimitHandler = async () => {
    setIsLoading(true);
    const payload = {
      customerId: id,
      loanLimit: +creditLimit,
    };
    try {
      const res = await axios.put(
        `${API_URL}/approve-customer`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(res.data.message);
      await getAllUsers().then(() => {
        setId("");
        setCreditLimit("");
        setIsLoading(false);
        setAction1((prev: any) => !prev);
      });
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsLoading(false)
      setId("");
      setCreditLimit("");
      setAction1((prev: any) => !prev);
    } finally {
      setIsLoading(false)
    }
  };

  const closeModal =()=> {
    setCreditLimit("")
    setAction1(false)
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center flex-col text-dark p-2 rounded-t-md mb-4">
          <div className="relative w-full">
            <button
              onClick={closeModal}
              className="text-dark absolute right-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <h3 className="font-semibold">Set User Credit Limit</h3>
          <input
            type="number"
            name="creditLimit"
            className="border p-3 my-4"
            value={creditLimit}
            onChange={(e) => setCreditLimit(e.target.value)}
            id="creditLimit"
            placeholder="Enter Credit Limit"
          />

          <div>
            <button
              type="button"
              onClick={setCreditLimitHandler}
              className="w-28 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
            >
              {isLoading ? <LoadingSpinner /> : "Save"}
            </button>
            <button
              type="button"
              className="w-28 px-5 py-3 bg-red-500 rounded text-white "
              onClick={() => setAction1((prev: any) => !prev)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditLimitModal;
